










































































































































































































































































































































import { RegistroOperacional } from '@/core/models/operacional';
import { RegistroOperacionalService } from '@/core/services/operacional';
import { AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component
export default class DetalharRegistroOperacional extends Vue {

    avaliar: boolean = false;
    overlay: boolean = false;
    
    service = new RegistroOperacionalService();
    item: RegistroOperacional = new RegistroOperacional();

    token: string = "";
    registroId: string = "";

    justificativa: string = "";
    aprovado: boolean = false;

    mounted() {

        this.registroId = this.$route.params.id;
        this.token = this.$route.params.token;

        this.GetAvaliar();
    }

    GetAvaliar(){
        this.service.GetAvaliar(this.registroId,this.token).then(
            res=>{
                this.item = res.data
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Validacao(validado: boolean){
        this.aprovado = validado;
        this.avaliar = true;
    }

    Avaliar(){

        if(!this.aprovado && this.justificativa.length == 0){
            AlertSimple('Aviso', "É necessário justificar a reprovação", 'warning')
        }
        else{
            this.service.Avaliar(this.registroId, this.token, this.justificativa, this.aprovado).then(
                res=>{
                    AlertSimpleRes("Aviso!", res);
                    this.GetAvaliar();
                    this.avaliar = false;
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

}
